import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import commonService from "../../services/common.service";
import { TagsState, TagsInitState } from "../../models/tags.model";
import LoaderComponent from "../loader/loader";
import ConceptHeaderComponent from "../navigation/navigation-header/concept-header/concept-header";
import RestaurantNavComponent from "../navigation/navigation-left/restaurant-navigation/restaurant-navigation";
import NavigationRightComponent from "../navigation/navigation-right/navigation-right";
import TagsCreateEditModal from "./tagsCreateEditModal";
import DeleteModalCard from "../card-components/delete-card/delete-card";
import TagsList from "./tagsList";
import {
  tagAdd,
  tagRemove,
  tagsRequest,
  tagUpdate,
} from "../../redux/actions/tags.action";

import "../../styles/units.scss";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { TAG_DATATYPE } from "../../constant/constant";

class TagsComponent extends Component<any> {
  isAddEditTagModalOpen: boolean = false;
  isRemoveTagModalOpen: boolean = false;
  state: TagsState;
  constructor(props: any) {
    super(props);
    this.state = TagsInitState;
    this.bindFunctions();
  }
  bindFunctions() {
    this.toggleTagFormModal = this.toggleTagFormModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.EditTag = this.EditTag.bind(this);
    this.RemoveTag = this.RemoveTag.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.SaveTag = this.SaveTag.bind(this);
  }
  componentDidMount() {
    this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
  }

  UNSAFE_componentWillReceiveProps(newProps: any) {
    if (!!newProps) {
      this._setStates("isFetching", newProps.isFetching);
      if (!!this.state.isUpdated && !newProps.isFetching) {
        this.props.getTags({ restaurantuuid: this.props.match.params.uuid });
        this._setStates("isUpdated", false);
      }
    }
  }

  public handleOptionsChange = (event: any, index: any) => {
    let moreValues = [...this.state.moreValues];
    if (event && event.target.value.trim() !== "")
      moreValues[index] = { index: index, value: event.target.value };
    else {
      moreValues[index] = { index: index, value: "" };
    }
    this._setStates("moreValues", moreValues);
  };

  public addMoreValues = (e: any) => {
    this._setStates("moreValues", [
      ...this.state.moreValues,
      { index: "", value: "" },
    ]);
  };

  public getSortedTags = () => {
    const tags: Array<any> = [];
    this.props.tags?.forEach((tag: any) => {
      tags.push({
        ...tag,
        name: commonService.applyLocalization(
          "restaurant",
          "name",
          tag?.locales
        ).name,
      });
    });
    return _.sortBy(tags, "name");
  };

  public removeValue = (index: any, item?: any) => {
    let moreValues = this.state.moreValues;
    if (moreValues[index].value === item.defaultValue?.value) {
      item.defaultValue = "";
    }
    moreValues.splice(index, 1);
    this._setStates("moreValues", moreValues);
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <LoaderComponent display={!!this.state.isFetching} />
        <TagsCreateEditModal
          addMoreValues={this.addMoreValues}
          handleChange={this.handleChange}
          handleOptionsChange={this.handleOptionsChange}
          resetMetaData={this.resetMetaData}
          isOpen={this.state.isAddEditTagModalOpen}
          onCancel={this.toggleTagFormModal}
          onSave={this.SaveTag}
          removeValue={this.removeValue}
          state={this.state}
        />
        <DeleteModalCard
          isOpen={this.state.isRemoveTagModalOpen}
          uuid={this.state.uuid}
          isFetching={!!this.state.isFetching}
          okDelete={this.RemoveTag}
          cancelDelete={this.toggleDeleteModal}
        />
        <div className="container-fluid">
          <ConceptHeaderComponent concept={"concept"} />
          <Row className="main light-theme">
            <Col xl={2} lg={3} className="hide-left-max">
              <RestaurantNavComponent
                display={"tags"}
                restaurantuuid={this.props.match.params.uuid}
              />
            </Col>
            <Col xl={8} lg={6}>
              <div className="white-box mb-3">
                <h4>
                  {t("tag.tag")} {this.state.isFetching}
                  <Button
                    className="ml-2"
                    color="primary"
                    size="sm"
                    onClick={this.toggleTagFormModal}
                  >
                    {t("tag.addNewTag")}
                  </Button>
                </h4>
                <TagsList
                  restaurantLang={this.props.restaurantLang}
                  tagList={this.getSortedTags()}
                  onEdit={this.EditTag}
                  onDelete={this.toggleDeleteModal}
                />
              </div>
            </Col>
            <Col xl={2} lg={3}>
              <NavigationRightComponent />
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  public _setStates(name: string, value: any): void {
    this.setState({ [name]: value });
    this.setRestriction(name, value);
  }

  public resetMetaData = () => {
    this.setState({
      tag_default_value: "",
      moreValues: [{ index: "", value: "" }],
      tags_range_from: "",
      tags_range_to: "",
    });
  };

  public setRestriction(name: string, value: any): void {
    if (name === "moreValues") {
      const options = value
        ?.filter((o: any) => o.value !== "")
        .map((o: any) => o.value);
      this.setState({
        restriction: options.length < 1 ? {} : { type: "option", options },
        tag_default_value: "",
      });
    } else if (["tags_range_from", "tags_range_to"].includes(name)) {
      const from =
        name === "tags_range_from"
          ? Number(value) || 0
          : Number(this.state.tags_range_from) || 0;
      const to =
        name === "tags_range_to"
          ? Number(value) || 0
          : Number(this.state.tags_range_to) || 0;
      this.setState({
        restriction: from === 0 && to === 0 ? {} : { type: "range", from, to },
        tag_default_value: "",
      });
    }
  }

  public toggleTagFormModal() {
    this.setState((prevState: any) => ({
      isAddEditTagModalOpen: !prevState.isAddEditTagModalOpen,
      moreValues: [{ index: "", value: "" }],
    }));
    if (this.state.isAddEditTagModalOpen) {
      this.setState(() => ({
        isEdit: false,
        tags_key: "",
        tags_name: "",
        tags_range_from: "",
        tags_range_to: "",
        tags_datatype: TAG_DATATYPE.NUMBER,
        uuid: "",
        tag_default_value: "",
        restriction: {},
      }));
    }
  }

  public EditTag(tagId: string) {
    const tagData = this.props.tags.find((data: any) => data.uuid === tagId);
    if (!tagData) return;

    const { key, locales, datatype, meta } = tagData;
    const { default: defaultValue, restriction } = meta || {};
    const { type, options, from, to } = restriction || {};

    const newState: any = {
      isAddEditTagModalOpen: !this.state.isAddEditTagModalOpen,
      isEdit: true,
      tags_key: key,
      tags_name: commonService.applyLocalization("restaurant", "name", locales)[
        "name"
      ],
      tags_datatype: datatype,
      tag_default_value: defaultValue,
      restriction,
      uuid: tagId,
    };

    if (type === "range" && datatype === TAG_DATATYPE.NUMBER) {
      newState.tags_range_from = from;
      newState.tags_range_to = to;
    }

    if (type === "option") {
      newState.moreValues = options?.map((option: any, index: any) => ({
        value: option,
        index: index,
      }));
      newState.moreValues.push({ index: "", value: "" });
    } else {
      newState.moreValues = [{ index: "", value: "" }];
    }

    this.setState(newState);
  }

  public handleChange(e: any) {
    this._setStates(e.target.name, e.target.value);
  }

  public RemoveTag() {
    this.props.removeTag({
      restaurantuuid: this.props.match.params.uuid,
      uuid: this.state.uuid,
    });
    this._setStates("isUpdated", true);
    this.toggleDeleteModal();
  }

  public toggleDeleteModal(tagId?: string) {
    this.setState((prevState: any) => ({
      isRemoveTagModalOpen: !prevState.isRemoveTagModalOpen,
      uuid: tagId ?? "",
    }));
  }

  public getTagFormPayload() {
    const formState: TagsState = this.state;
    const meta: any = {};
    meta.default = formState.tag_default_value;
    if (!_.isEmpty(formState.restriction)) {
      meta.restriction = formState.restriction;
    }

    return {
      data: {
        locales: {
          [this.props.restaurantLang[0].code]: {
            name: formState.tags_name,
          },
        },
        key: formState?.tags_key,
        datatype: formState.tags_datatype,
        meta: !_.isEmpty(meta) ? meta : undefined,
      },
      restaurantuuid: this.props.match.params.uuid,
    };
  }

  public SaveTag() {
    const payload: any = this.getTagFormPayload();
    if (this.state.isEdit) {
      payload.uuid = this.state.uuid;
      this.props.updateTag(payload);
    } else {
      this.props.addTag(payload);
    }
    this.toggleTagFormModal();
    this._setStates("isUpdated", true);
  }
}

const mapStateToProps: any = (state: any) => {
  let isFetching =
    state.tagAdd.isFetching ||
    state.tagRemove.isFetching ||
    state.tagUpdate.isFetching ||
    state.tags.isFetching;
  let failure =
    state.tagAdd.failure || state.tagRemove.failure || state.tags.failure;
  return {
    restaurantLang: commonService.getRestaurantDetails()?.languages || [],
    tags: state.tags.data,
    tagAddStatus: state.tagAdd.data,
    tagRemoveStatus: state.tagRemove.data,
    tagUpdateStatus: state.tagUpdate.data,
    isFetching: isFetching,
    failure: failure,
  };
};

const mapDispatchToProps: object = (dispatch: any) => {
  return {
    addTag: (credentials: any) => {
      dispatch(tagAdd(credentials));
    },
    getTags: (credentials: any) => {
      dispatch(tagsRequest(credentials));
    },
    removeTag: (credentials: any) => {
      dispatch(tagRemove(credentials));
    },
    updateTag: (credentials: any) => {
      dispatch(tagUpdate(credentials));
    },
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(TagsComponent))
);
