import React from "react";
import { withRouter, useParams } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import LabelPrint from "../../components/printer/label-print";
import { supplierPrintLabel } from "../../services/supplier.restaurants.service";

const SupplierLabelPrintPage: React.FC = (props: any) => {
  const { uuid, supplierUuid, templateUuid } = useParams<{
    uuid: string;
    supplierUuid: string;
    templateUuid: string;
  }>();
  const { t } = useTranslation();
  const payload = {
    restaurantuuid: uuid,
    supplierUuid: supplierUuid,
    data: {
      template_uuid: templateUuid,
    },
  };
  return <LabelPrint payload={payload} printFn={supplierPrintLabel} />;
};

export default withTranslation()(withRouter(SupplierLabelPrintPage));
