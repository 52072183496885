import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Input,
  FormGroup,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Row,
} from "reactstrap";
import LoaderComponent from "../loader/loader";
import { GRAY } from "../../constant/constant";
import { isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCheckCircle,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

interface Item {
  uuid: string;
  description: string;
  groupName?: string;
  comment?: string;
}

interface MultiSelectListProps {
  id: string;
  items: Item[];
  initialSelectedUuids?: string[];
  onSelectionChange?: (selectedUuids: string[]) => void;
  pageSize?: number;
  disableItems?: boolean;
  enableComment?: boolean;
  onCommentChange?: (comments: Map<string, string>) => void;
  info?: Map<string, any>;
}

const MultiSelectList = ({
  id = "",
  items = [],
  initialSelectedUuids = [],
  onSelectionChange,
  pageSize = 20,
  disableItems = false,
  enableComment = false,
  onCommentChange,
  info = new Map(),
}: MultiSelectListProps): React.ReactElement | null => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<string>("");
  const [selectedUuids, setSelectedUuids] = useState<Set<string>>(
    new Set(initialSelectedUuids)
  );
  const [selectedItemIds, setSelectedItemIds] = useState<Set<string>>();
  const [displayedItemCount, setDisplayedItemCount] =
    useState<number>(pageSize);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const filteredItems = useMemo<Item[]>(() => {
    return items
      .filter(
        (item) =>
          item &&
          item.description &&
          item.uuid &&
          item.description.toLowerCase().includes(filter.toLowerCase())
      )
      .slice(0, displayedItemCount);
  }, [items, filter, displayedItemCount]);
  const [comments, setComments] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    setSelectedUuids(new Set(initialSelectedUuids));
  }, [initialSelectedUuids]);

  const handleItemSelect = useCallback(
    (uuid: string) => {
      const newSelectedItems = new Set(selectedItemIds);
      const currentSelectedUuids = new Set(selectedUuids);
      if (currentSelectedUuids.has(uuid)) {
        currentSelectedUuids.delete(uuid);
        if (newSelectedItems.has(uuid)) {
          newSelectedItems?.delete(uuid);
        }
      } else {
        currentSelectedUuids.add(uuid);
        newSelectedItems.add(uuid);
      }
      setSelectedUuids(currentSelectedUuids);
      setSelectedItemIds(newSelectedItems);

      if (onSelectionChange) {
        onSelectionChange(Array.from(currentSelectedUuids));
      }
    },
    [selectedUuids, selectedItemIds, onSelectionChange]
  );

  const handleItemComment = useCallback(
    (uuid: string, event: any) => {
      if (!selectedItemIds?.has(uuid)) return;

      const allComments = new Map(comments);
      allComments.set(uuid, event.target.value);
      setComments(allComments);

      if (onCommentChange) {
        onCommentChange(allComments);
      }
    },
    [selectedItemIds, comments, onCommentChange]
  );

  const handleScroll = useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    if (
      container.scrollHeight - container.scrollTop <=
      container.clientHeight + 20
    ) {
      setDisplayedItemCount((prevCount) =>
        Math.min(prevCount + pageSize, items.length)
      );
    }
  }, [pageSize, items.length]);

  const handleClearFilter = useCallback(() => {
    setFilter("");
    setDisplayedItemCount(pageSize);
  }, [pageSize]);

  const listItems = () => {
    let groupName = "";
    return filteredItems.map((item: Item) => {
      const groupNode = item?.groupName && groupName !== item?.groupName && (
        <ListGroupItem
          key={`${item.uuid}-group`}
          className="d-flex align-items-center pl-2"
          style={{
            border: 0,
            borderBottom: "solid 1px rgba(0, 0, 0, .05)",
            borderRadius: 0,
            fontWeight: 500,
            color: "#8c8c8c",
          }}
        >
          {item.groupName}
        </ListGroupItem>
      );
      groupName = item?.groupName || "";
      const itemInfo = info && info.has(item.uuid) ? info.get(item.uuid) : null;
      return (
        <React.Fragment key={item.uuid}>
          {groupNode}
          <ListGroupItem
            key={item.uuid}
            className=""
            style={{
              border: 0,
              borderBottom: "solid 1px rgba(0, 0, 0, .05)",
              borderRadius: 0,
            }}
          >
            <Row md={12} className="p-0 d-flex align-items-center">
              <CustomInput
                type="checkbox"
                id={`${id}-item-${item.uuid}`}
                className="mr-2"
                checked={selectedUuids.has(item.uuid)}
                onChange={() => handleItemSelect(item.uuid)}
                label={item.description}
                disabled={disableItems}
              />
              {enableComment &&
                selectedItemIds?.has(item.uuid) &&
                info &&
                !itemInfo && (
                  <Input
                    type="textarea"
                    name="comment"
                    className="ml-auto"
                    style={{
                      width: "30%",
                      height: "2.5rem",
                    }}
                    onChange={(e: any) => handleItemComment(item.uuid, e)}
                    disabled={disableItems}
                  />
                )}
            </Row>
            {itemInfo && (
              <Row md={12} className="p-0 pt-1">
                <small className="pl-4" style={{ color: GRAY }}>
                  <FontAwesomeIcon icon={faCheckCircle} color="#ccc" />
                  {itemInfo.validatedBy}{" "}
                  <span style={{ fontSize: ".8rem" }}>
                    <FontAwesomeIcon icon={faCalendarCheck} color="#ccc" />
                    {itemInfo.validatedAt}
                  </span>{" "}
                  {!isEmpty(itemInfo.comment) && (
                    <>
                      <FontAwesomeIcon icon={faComments} color="#ccc" />
                      <i>{itemInfo.comment}</i>
                    </>
                  )}
                </small>
              </Row>
            )}
          </ListGroupItem>
        </React.Fragment>
      );
    });
  };

  if (items.length === 0) {
    return (
      <div className="text-center text-muted p-4">
        {t("common.noItemsToDisplay")}
      </div>
    );
  }

  return (
    <div>
      <FormGroup style={{ margin: 0 }}>
        <div className="position-relative">
          <Input
            type="text"
            name={`${id}-filter`}
            id={`${id}-filter`}
            placeholder={`${t("common.search")}...`}
            value={filter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFilter(e.target.value);
              setDisplayedItemCount(pageSize);
            }}
            style={{
              borderRadius: "4px 4px 0 0",
              paddingRight: filter ? "30px" : undefined,
            }}
          />
          {filter && (
            <button
              type="button"
              onClick={handleClearFilter}
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "none",
                border: "none",
                color: "#6c757d",
                cursor: "pointer",
                zIndex: 10,
              }}
            >
              ✕
            </button>
          )}
        </div>
      </FormGroup>

      <div
        ref={scrollContainerRef}
        onScroll={handleScroll}
        style={{
          height: "400px",
          overflowY: "auto",
          border: "1px solid #ddd",
          borderRadius: "0 0 4px 4px",
          borderTop: 0,
        }}
      >
        <ListGroup>{listItems()}</ListGroup>
        <LoaderComponent display={displayedItemCount < items.length} />
      </div>
    </div>
  );
};

export default MultiSelectList;
