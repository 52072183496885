export interface supplierState {
  isFetching: boolean;
  restaurant: any;
  supplierList: any;
  editmodal: boolean;
  addmodal: boolean;
  open: boolean;
  isUpdated: boolean;
  uuid: boolean;
  name: string;
  email: string;
  phoneNumber: number;
  vendorNumber: string;
  restaurantInventoryAccess: number;
  restaurantAllowPartialDelivery: boolean;
  emailTemplateOptions: any;
  emailTemplate: any;
  allEmailTemplates: any;
  restaurantOptions: any;
  deliverySchedule: string;
  isValidCrontab: boolean;
  openLabelPrintModal: boolean;
  printPageUrl: string;
}
export const supplierStateInit = {
  isFetching: false,
  restaurant: {},
  supplierList: [],
  editmodal: false,
  addmodal: false,
  open: false,
  isUpdated: false,
  uuid: false,
  name: "",
  email: "",
  phoneNumber: 0,
  vendorNumber: "",
  restaurantInventoryAccess: 0,
  restaurantAllowPartialDelivery: false,
  emailTemplateOptions: [],
  emailTemplate: {},
  allEmailTemplates: [],
  restaurantOptions: [],
  deliverySchedule: "",
  isValidCrontab: true,
  openLabelPrintModal: false,
  printPageUrl: "",
};
