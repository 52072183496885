import apiCall from "./api.call.config";

export async function getRestaurantSuppliers(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/supplier"
    );
  } catch (e) {
    throw e;
  }
}

export async function getSupplierRestaurants(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/supplier-restaurants"
    );
  } catch (e) {
    throw e;
  }
}

export async function getSupplierIngredients(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-restaurant/" +
        params.credentials.supplieruuid +
        "/ingredients?sort=" +
        (params.credentials?.sort && params.credentials.sort !== ""
          ? params.credentials.sort
          : "serviceSet:asc||sortOrder:asc") +
        "&pageNo=" +
        params.credentials.pageNo +
        `&pageSize=20&filter=label=[${params.credentials.labelArray}]||name=${params.credentials.name}||status=${params.credentials.status}`
    );
  } catch (e) {
    throw e;
  }
}

export async function getUnits(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-restaurant/" +
        params.credentials.supplieruuid +
        "/units"
    );
  } catch (e) {
    throw e;
  }
}

export async function getIngredientDetails(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-restaurant/" +
        params.credentials.supplieruuid +
        "/ingredient/" +
        params.credentials.ingredientuuid +
        "/details"
    );
  } catch (e) {
    throw e;
  }
}

export async function saveSupplierIngredients(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-restaurant/" +
        params.credentials.supplieruuid +
        "/ingredients",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function orderShoppingCartItems(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-restaurant-orders",
      params.credentials.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getLabels(url: string, params: any) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-restaurant/" +
        params.credentials.supplieruuid +
        "/labels"
    );
  } catch (e) {
    throw e;
  }
}

export async function getRestaurantClients(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.credentials.restaurantuuid + "/supplier-clients"
    );
  } catch (e) {
    throw e;
  }
}

export async function getSuppliersWithServiceSetInventoryAccess(
  url: string,
  params: any
) {
  try {
    return await apiCall.get(
      url +
        "/" +
        params.credentials.restaurantuuid +
        "/supplier-serviceset-inventory-access"
    );
  } catch (e) {
    throw e;
  }
}

export async function importAllSupplierItems(url: string, params: any) {
  try {
    return await apiCall.post(
      url + "/" + params.restaurantuuid + "/import-all-supplier-items",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export async function getActiveSupplierItemImports(url: string, params: any) {
  try {
    return await apiCall.get(
      url + "/" + params.restaurantuuid + "/active-supplier-item-imports"
    );
  } catch (e) {
    throw e;
  }
}

export async function supplierPrintLabel(url: string, params: any) {
  try {
    return await apiCall.post(
      url +
        "/" +
        params.restaurantuuid +
        "/supplier/" +
        params.supplierUuid +
        "/print-label",
      params.data
    );
  } catch (e) {
    throw e;
  }
}

export default {
  getRestaurantSuppliers,
  getSupplierRestaurants,
  getSupplierIngredients,
  getIngredientDetails,
  getUnits,
  orderShoppingCartItems,
  saveSupplierIngredients,
  getLabels,
  getRestaurantClients,
  getSuppliersWithServiceSetInventoryAccess,
  importAllSupplierItems,
  getActiveSupplierItemImports,
  supplierPrintLabel,
};
