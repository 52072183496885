import mobiscroll from "@mobiscroll/react";
import i18n from "../i18n";
import {
  ACCESS_TOKEN,
  REDIRECT_URL,
  USER_RIGHTS,
  GENERATED_EMAIL_PREFIX,
} from "../constant/constant";
import _ from "lodash";
import { createBrowserHistory } from "history";

function createImageFormData(value: any): any {
  if (!!value) {
    const formData = new FormData();
    formData.append("object_type", value.object_type);
    formData.append("object_uuid", value.object_uuid);
    formData.append("images[]", value.images);
    if (value.entity_type) {
      formData.append("entity_type", value.entity_type);
    }
    if (value.entity_uuid) {
      formData.append("entity_uuid", value.entity_uuid);
    }
    return formData;
  } else {
    return;
  }
}

function createVideoFormData(value: any): any {
  if (!!value) {
    const formData = new FormData();
    formData.append("object_type", value.object_type);
    formData.append("object_uuid", value.object_uuid);
    formData.append("videos[]", value.videos);
    if (value.entity_type) {
      formData.append("entity_type", value.entity_type);
    }
    if (value.entity_uuid) {
      formData.append("entity_uuid", value.entity_uuid);
    }
    return formData;
  } else {
    return;
  }
}

export const formatDate = (date: Date, format: string): string => {
  const map: { [key: string]: string } = {
    dd: String(date.getDate()).padStart(2, "0"),
    mm: String(date.getMonth() + 1).padStart(2, "0"),
    yyyy: String(date.getFullYear()),
    H: String(date.getHours()).padStart(2, "0"),
    i: String(date.getMinutes()).padStart(2, "0"),
    s: String(date.getSeconds()).padStart(2, "0"),
  };

  return format.replace(/dd|mm|yyyy|H|i|s/gi, (matched) => map[matched]);
};

function dateFormat(date: Date): any {
  if (!!date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " " +
      addZeroBefore(date.getHours()) +
      ":" +
      addZeroBefore(date.getMinutes()) +
      ":" +
      addZeroBefore(date.getSeconds())
    );
  } else {
    return "";
  }
}

function dateTime(date: Date): any {
  if (!!date) {
    return new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate(),
      new Date().getHours(),
      new Date().getMinutes(),
      new Date().getSeconds()
    );
  } else {
    return new Date();
  }
}

function addZeroBefore(n: any) {
  return (n < 10 ? "0" : "") + n;
}

function clearUserData() {
  localStorage.clear();
}

function header(): any {
  let lang: any = getRestaurantDetails()?.restLanguages?.[0]?.code;
  if (!lang) lang = localStorage.getItem("i18nextLng");
  let head = {
    Authorization: "Bearer " + localStorage.getItem(ACCESS_TOKEN),
    "Cache-Control": "no-cache",
    Locale: lang !== null ? lang : "en-gb",
    "content-type": "application/json",
  };
  return head;
}

function getFontContrast(hex: string): any {
  let C, L;
  let R, G, B;
  let fontcolor;

  R = parseInt(hex.slice(1, 3), 16);
  G = parseInt(hex.slice(3, 5), 16);
  B = parseInt(hex.slice(5, 7), 16);
  C = [R / 255, G / 255, B / 255];
  for (let i = 0; i < C.length; ++i) {
    if (C[i] <= 0.03928) {
      C[i] = C[i] / 12.92;
    } else {
      C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4);
    }
  }
  L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];

  if (L > 0.179) {
    fontcolor = "#000000";
  } else {
    fontcolor = "#FFFFFF";
  }
  return fontcolor;
}
function getDate(date: any) {
  let q = new Date(date);
  let m: any = q.getMonth() + 1;
  if (m < 10) {
    m = "0" + m;
  }
  let d: any = q.getDate();
  if (d < 10) {
    d = "0" + d;
  }
  let y: any = q.getFullYear();

  return y + "-" + m + "-" + d;
}

function toastService(message: any, type: string, messageDetails?: any): any {
  message =
    message !== "" ? message : getValueFromSingleKeyJson(messageDetails);
  if (message !== "") {
    return mobiscroll.toast({
      message: message,
      color: type,
      duration: 5000,
    });
  } else {
    return mobiscroll.snackbar({
      message: i18n.t("fallback.somethingWentWrong"),
      color: type,
      duration: 20000,

      button: {
        text: i18n.t("common.copyError"),
        action: function () {
          navigator.clipboard.writeText(messageDetails);
          mobiscroll.toast({
            message: i18n.t("common.copyErrorSuccess"),
            color: "info",
            duration: 2000,
          });
        },
      },
    });
  }
}

function getValueFromSingleKeyJson(jsonString: string): string {
  try {
    const jsonObject = JSON.parse(jsonString);
    if (typeof jsonObject === "object" && jsonObject !== null) {
      const keys = Object.keys(jsonObject);
      if (keys.length === 1) {
        return jsonObject[keys[0]];
      }
    }
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }

  return "";
}

function convertDateToMsFromString(date: string) {
  let d = parseInt("0" + date.substr(0, 2).replace("_", ""));
  let h = parseInt("0" + date.substr(4, 2).replace("_", ""));
  let m = parseInt("0" + date.substr(7, 2).replace("_", ""));
  let s = parseInt("0" + date.substr(10, 2).replace("_", ""));
  return (((d * 24 + h) * 60 + m) * 60 + s) * 1000;
}

function convertDateToMs(day: any, hours: any, minutes: any, second: any) {
  let dayMs = day * 24 * 60 * 60 * 1000;
  let hoursMs = Math.floor(hours * 60 * 60 * 1000);
  let minutesMs = Math.floor(minutes * 60 * 1000);
  let secondMs = Math.floor(second * 1000);
  return dayMs + hoursMs + minutesMs + secondMs;
}

function getTimeInMs(time: any) {
  let str = time.toString();
  let day = str.slice(0, 2);
  let hr = str.slice(4, 6);
  let min = str.slice(7, 9);
  let sec = str.slice(10, 12);
  return convertDateToMs(day, hr, min, sec);
}

function convertMsToDate(milliseconds: any) {
  var day, hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  return (
    ("0" + day).slice(-2) +
    ":" +
    ("0" + hour).slice(-2) +
    ":" +
    ("0" + minute).slice(-2) +
    ":" +
    ("0" + seconds).slice(-2)
  );
}

function getMsToDate(milliseconds: any) {
  var day, hour, minute, seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  return (
    ("0" + day).slice(-2) +
    "d " +
    ("0" + hour).slice(-2) +
    ":" +
    ("0" + minute).slice(-2) +
    ":" +
    ("0" + seconds).slice(-2)
  );
}

function getDateCut(date: string) {
  let d = parseInt("0" + date.substr(0, 2).replace("_", ""));
  let h = parseInt("0" + date.substr(4, 2).replace("_", ""));
  let m = parseInt("0" + date.substr(7, 2).replace("_", ""));
  let s = parseInt("0" + date.substr(10, 2).replace("_", ""));

  let res = "";
  if (d > 0) {
    res += d + "d ";
  }
  res +=
    ("0" + h).slice(-2) + ":" + ("0" + m).slice(-2) + ":" + ("0" + s).slice(-2);
  return res;
}

function applyLocalization(
  type: string,
  key: any,
  locales: any,
  selectedLang?: any
) {
  let languages: any = [];
  let locale: any = {};
  if (type === "restaurant" || type === "translate") {
    const restaurantDetails: any = _.cloneDeep(getRestaurantDetails());
    if (Object.keys(restaurantDetails?.restLanguages || {})?.length > 0) {
      languages = restaurantDetails.restLanguages;
    } else {
      languages = restaurantDetails?.languages || [];
      const langIndex: number = languages.findIndex(
        (lan: any) => lan.uuid === restaurantDetails?.language
      );
      if (langIndex > 0) {
        const restLanguage: any = languages.splice(langIndex, 1)[0];
        languages.unshift(restLanguage);
        restaurantDetails["restLanguages"] = languages;
        setRestaurantDetails(restaurantDetails);
      }
    }
    if (languages.length <= 0) {
      languages = JSON.parse(
        localStorage.getItem("RESTAURANTLANGUAGE") || "[]"
      );
    }
  }
  if (type === "user")
    languages = JSON.parse(localStorage.getItem("USERLANGUAGE") || "[]");
  languages?.forEach((lang: any) => {
    if (
      locales &&
      locales[lang.code] &&
      !!locales[lang.code][key] &&
      selectedLang !== lang.code &&
      Object.keys(locale).length === 0
    ) {
      locale = locales[lang.code];
    }
  });
  if (Object.keys(locale).length === 0) {
    locales &&
      Object.keys(locales).forEach((lang: any) => {
        if (
          !!locales[lang] &&
          !!locales[lang][key] &&
          !!locales[lang] &&
          selectedLang !== lang &&
          Object.keys(locale).length === 0
        ) {
          locale = locales[lang];
        }
      });
  }
  return locale;
}

function stickyEventListener() {
  const stickyElm = document.querySelector(".bf-sticky-filter");
  const observer: any = new IntersectionObserver(
    ([e]: any) => {
      e.target.classList.toggle("isSticky", e.intersectionRatio < 0.9);
    },
    { threshold: [1] }
  );
  observer.observe(stickyElm);
}

function displayDigitalAmount(price: any) {
  price = !price ? 0 : price;
  let minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;

  let formattedPrice: any = 0;
  formattedPrice = price / Math.pow(10, minorUnit);
  formattedPrice = formattedPrice.toFixed(minorUnit);

  return formattedPrice;
}

function roundToCashAmount(price: any, isDisplayAmount?: boolean) {
  let minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;
  let minimalCashDenomination = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minimal_cash_denomination;
  let displayAmount = displayDigitalAmount(price);
  if (minimalCashDenomination) {
    if (!isDisplayAmount)
      return (
        Math.round(displayAmount / minimalCashDenomination) *
        minimalCashDenomination
      )?.toFixed(minorUnit);
    else
      return (
        Math.round(price / minimalCashDenomination) * minimalCashDenomination
      )?.toFixed(minorUnit);
  } else {
    return isDisplayAmount ? price : displayAmount;
  }
}

function validateEnteredCashAmount(amount: any) {
  let minorUnit = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minor_units;
  let minimalCashDenomination = JSON.parse(
    localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}"
  )?.minimal_cash_denomination;

  let price: any = (Number(amount) * Math.pow(10, minorUnit)).toFixed(
    minorUnit
  );
  if (minimalCashDenomination) {
    let quotient: any =
      price / (minimalCashDenomination * Math.pow(10, minorUnit));
    let decimalOfQuotient: any = parseFloat(String(quotient).split(".")[1]);

    if (decimalOfQuotient) {
      return true;
    } else return false;
  } else return false;
}

function hasPermissionToAccess(
  permission: string,
  restaurant?: string,
  redirectionURL?: string
) {
  const router: any = createBrowserHistory();
  const restaurantId: any = !!restaurant
    ? restaurant
    : JSON.parse(localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "{}")
        ?.uuid;
  if (!restaurantId) return false;
  if (!localStorage.getItem("USER_RESTAURANT_RIGHTS")) {
    if (redirectionURL && restaurant)
      localStorage.setItem(
        REDIRECT_URL,
        JSON.stringify({
          restaurantUuid: restaurant || "",
          url: redirectionURL || "",
        })
      );
    router.push(`/restaurant/${restaurantId}/home`);
    return;
  } else {
    const userRights = JSON.parse(
      localStorage.getItem("USER_RESTAURANT_RIGHTS") || "{}"
    )[restaurantId];
    if (
      userRights?.includes(USER_RIGHTS?.SUPER_ADMIN) ||
      userRights?.includes(permission)
    ) {
      return true;
    }
  }
  return false;
}

function hasOnlyPermission(
  permission: string,
  restaurantUuid: string
): boolean {
  const userRights = JSON.parse(
    localStorage.getItem("USER_RESTAURANT_RIGHTS") || "{}"
  )[restaurantUuid];
  if (userRights?.length === 1 && userRights.includes(permission)) {
    return true;
  }
  return false;
}

function hasAnyPermission(
  permissions: Array<string>,
  restaurantUuid: string
): boolean {
  const userRights = JSON.parse(
    localStorage.getItem("USER_RESTAURANT_RIGHTS") || "{}"
  )[restaurantUuid];
  if (userRights?.includes(USER_RIGHTS?.SUPER_ADMIN)) return true;
  if (
    userRights?.length > 0 &&
    permissions.reduce(
      (count: number, permission: string) =>
        count + (userRights.includes(permission) ? 1 : 0),
      0
    ) > 0
  ) {
    return true;
  }
  return false;
}

function getBestMatchUnitWithQty(
  quantity: number,
  currentUnit: string,
  units: any
) {
  let best: any = null;
  let score = getBestScore(quantity);
  for (let i = 0; i < units.length; i++) {
    const conversionFactor = 1 / units[i].conversionFactor;
    const q = conversionFactor * quantity;
    const newScore = getBestScore(q);

    if (best != null && newScore === score) {
      if (score === 4) {
        if (units[i].conversionFactor > best.conversionFactor) {
          continue;
        }
      } else if (score === 5) {
        if (units[i].conversionFactor < best.conversionFactor) {
          continue;
        }
      }
    }

    if (newScore < score) {
      score = newScore;
      best = units[i];
    } else if (best != null) {
      if ((newScore === 4 && score === 4) || (newScore === 5 && score === 5)) {
        best = units[i];
      }
    }
    if (score === 1) {
      break;
    }
  }

  let u: any = best;
  if (best === null) {
    u = units.find((unit: any) => unit.value === currentUnit);
  }

  let outputQty: any = getSignificantDigit(
    quantity / (u?.conversionFactor || 1)
  );

  if (isNaN(outputQty)) outputQty = "";
  return {
    quantity: outputQty,
    unit: u,
  };
}

function getBestScore(quantity: number) {
  if (quantity >= 1000) {
    return 4;
  } else if (quantity >= 100) {
    return 1;
  } else if (quantity >= 10) {
    return 2;
  } else if (quantity >= 1) {
    return 3;
  } else {
    return 5;
  }
}

function getUrlParam(param: string) {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  return params.get(param);
}

let restaurantDetails: any = {};

const getRestaurantDetails = () => {
  if (Object.keys(restaurantDetails).length > 0) {
    return restaurantDetails;
  } else if (localStorage.getItem("ACTIVE_RESTAURANT_DETAILS")) {
    return JSON.parse(localStorage.getItem("ACTIVE_RESTAURANT_DETAILS") || "");
  } else return {};
};

const setRestaurantDetails = (details: any) => {
  restaurantDetails = details;
};
export const shouldOpenCreateModal = () => {
  return getUrlParam("openCreateForm");
};
export const shouldOpenEditModal = () => {
  return getUrlParam("openEditForm");
};

export const getEditItemId = () => {
  return getUrlParam("editItemId");
};

const getRelateUnitDropdowns = (
  unitId: string,
  selectedUnits: Array<any> = [],
  allUnits: Array<any> = [],
  conversionFactor: number = 1,
  recipeId?: string
) => {
  const recipeUnit = Array.isArray(allUnits)
    ? allUnits?.find((unit: any) => {
        if (unit?.recipe_uuid)
          return unit.uuid === unitId && unit.recipe_uuid === recipeId;
        return unit.uuid === unitId;
      })
    : [];

  if (!recipeUnit) return [];
  let units: Array<any> = [
    {
      label: applyLocalization("restaurant", "name", recipeUnit.locales)[
        "name"
      ],
      value: recipeUnit.uuid,
      symbol: applyLocalization("restaurant", "name", recipeUnit.locales)[
        "symbol"
      ],
      conversionFactor,
    },
  ];
  const fromUnits = recipeUnit?.from_conversions;
  const toUnits = recipeUnit?.to_conversions;

  if (fromUnits?.length > 0) {
    fromUnits.forEach((from: any) => {
      if (
        (recipeId && from?.recipe_uuid && recipeId !== from.recipe_uuid) ||
        (!recipeId && from?.recipe_uuid)
      )
        return;
      const itsExist = selectedUnits?.filter(
        (allUnit: any) => allUnit?.value === from.toUnit
      );
      if (!itsExist?.length && recipeUnit.uuid !== from.toUnit) {
        const allUnitsMerged = _.concat(selectedUnits, units);
        const fromUnit: any = getRelateUnitDropdowns(
          from.toUnit,
          allUnitsMerged,
          allUnits,
          conversionFactor / from.conversion_factor,
          recipeId
        );

        if (!!fromUnit) units = _.concat(units, fromUnit);
      }
    });
  }

  if (toUnits?.length > 0) {
    toUnits.forEach((to: any) => {
      if (
        (recipeId && to?.recipe_uuid && recipeId !== to.recipe_uuid) ||
        (!recipeId && to?.recipe_uuid)
      )
        return;
      const itsExist = selectedUnits?.filter(
        (allUnit: any) => allUnit?.value === to.fromUnit
      );
      if (!itsExist?.length && recipeUnit.uuid !== to.fromUnit) {
        const allUnitsMerged = _.concat(selectedUnits, units);
        const toUnit: any = getRelateUnitDropdowns(
          to.fromUnit,
          allUnitsMerged,
          allUnits,
          conversionFactor * to.conversion_factor,
          recipeId
        );

        if (!!toUnit) units = _.concat(units, toUnit);
      }
    });
  }

  return _.uniqBy(units, function (e) {
    return e.value;
  });
};

function getSignificantDigit(quantity: number, precisionCount: number = 3) {
  // return parseFloat(quantity.toPrecision(precisionCount));
  const dig: number =
    quantity == 0 ? 0 : Math.floor(Math.log10(Math.abs(quantity)));
  const pow: number = Math.pow(10, dig - precisionCount + 1);
  const res: number = Math.round(quantity / pow) * pow;
  if (dig >= precisionCount) {
    return Number(res.toFixed(0));
  } else {
    return Number(res.toFixed(Math.abs(dig - precisionCount + 1)));
  }
}

function getSignificantDigitNumberForSave(
  quantity: number,
  precisionCount: number = 3
) {
  return parseFloat(quantity.toPrecision(precisionCount));
  const dig: number =
    quantity == 0 ? 0 : Math.floor(Math.log10(Math.abs(quantity)));
  const pow: number = Math.pow(10, dig - precisionCount + 1);
  const res: number = Math.round(quantity / pow) * pow;
  return res;
}

function isGeneratedEmail(email: string | undefined) {
  return email ? email.startsWith(GENERATED_EMAIL_PREFIX) : true;
}

export default {
  dateFormat,
  displayDigitalAmount,
  createImageFormData,
  clearUserData,
  createVideoFormData,
  header,
  dateTime,
  applyLocalization,
  getFontContrast,
  getRelateUnitDropdowns,
  toastService,
  getTimeInMs,
  convertMsToDate,
  convertDateToMsFromString,
  getDateCut,
  getMsToDate,
  getDate,
  getSignificantDigit,
  roundToCashAmount,
  stickyEventListener,
  validateEnteredCashAmount,
  hasPermissionToAccess,
  hasOnlyPermission,
  hasAnyPermission,
  getBestMatchUnitWithQty,
  getUrlParm: getUrlParam,
  shouldOpenCreateModal,
  getRestaurantDetails,
  setRestaurantDetails,
  getSignificantDigitNumberForSave,
  isGeneratedEmail,
};
